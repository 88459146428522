import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  useParams,
  useNavigate,
  Navigate,
  useOutletContext,
} from "react-router-dom";
import useSWR from "swr";
import useFetchApi from "hooks/useFetchApi";
import { useAuth } from "components/AuthProvider";
import { useApiEndpoint } from "ApiEndpointContext";
import axios from "axios";
import Main from "components/Main";
import StatusClient from "components/StatusClient";
import btnBack from "images/icons/back.svg";
import Tab from "components/Tab";
import Button from "components/Button";
import Dialog from "components/Dialog";
import { ReactComponent as LocationIcon } from "images/icons/c-location.svg";
import { ReactComponent as ProfileIcon } from "images/icons/c-profile.svg";
import { ReactComponent as PhoneIcon } from "images/icons/c-phone.svg";
import { ReactComponent as EmailIcon } from "images/icons/c-mail.svg";
import { ReactComponent as LogementIcon } from "images/icons/c-logement.svg";
import { ReactComponent as ToolIcon } from "images/icons/c-tool.svg";
import { ReactComponent as EnergyIcon } from "images/icons/c-energy.svg";
// import { ReactComponent as RenewIcon } from "images/icons/c-renew.svg";
import { ReactComponent as EuroIcon } from "images/icons/c-euro.svg";
import { ReactComponent as DocIcon } from "images/icons/c-doc.svg";
import { ReactComponent as CakeIcon } from "images/icons/c-cake.svg";
import { ReactComponent as Doc2Icon } from "images/icons/c-doc2.svg";
import { ReactComponent as WindowIcon } from "images/icons/c-window.svg";
import { ReactComponent as CalendarIcon } from "images/icons/c-calendar.svg";
import { ReactComponent as DownloadIcon } from "images/icons/c-download.svg";
import { ReactComponent as Euro } from "images/icons/euro-blue.svg";
import { formatFileSize, transformTimestamp } from "functions";
import { ReactComponent as ViewIcon } from "images/icons/view.svg";
import { ReactComponent as DlIcon } from "images/icons/download.svg";
import Loader from "components/Loader";

function InfoBlock({ logo, label, value }) {
  const Logo = logo;
  return (
    <div className="flex">
      <div className="m-1 pr-2">
        <Logo />
      </div>

      <div>
        <p className="text-xs">{label}</p>
        <p className="text-sm">{value || "-"}</p>
      </div>
    </div>
  );
}
InfoBlock.propTypes = {
  logo: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  value: PropTypes.any,
};

export default function SimulationClients() {
  const [fetchApi] = useFetchApi();
  const { fileID } = useParams();
  const role = useOutletContext();
  const apiEndpoint = useApiEndpoint();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogError, setDialogError] = useState("");
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [resultID, setResultID] = useState(undefined);
  const auth = useAuth();
  const urlFetch = `simulations/${fileID}?populate=*`;
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate("/simulations-clients", { replace: true }),
    [navigate],
  );
  const { data, isLoading, error } = useSWR(urlFetch, fetchApi);
  const borderStyle = "border-0.5 border-stone-400 rounded-md";
  // console.log(data)
  const transformerDossier = async () => {
    setDialogError(null);
    setDialogLoading(true);
    setDialogSuccess(false);
    try {
      const apiUrl = `${apiEndpoint}/api/simulation/convert-to-dossier/${fileID}`;
      const response = await axios.post(apiUrl, "", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setResultID(response.data.id);
      setDialogSuccess(true);
    } catch (error) {
      console.warn(error);
      const errorMessageDetails = error?.response?.data?.error?.message;
      const errorMessage = error?.response?.data?.message;
      setDialogError(
        errorMessageDetails && errorMessage
          ? `${errorMessage} ${errorMessageDetails}`
          : `${error}`,
      );
    } finally {
      setDialogLoading(false);
    }
    // console.log(fileID)
  };

  if (isLoading || error) {
    return <Loader isLoading={isLoading} error={error} />;
  } else {
    const client = {
      ...data.data.attributes,
    };
    const infoBeneficiare = [
      {
        icon: ProfileIcon,
        label: client.civilite,
        value: `${client.prenom} ${client.nom}`,
      },
      { icon: LocationIcon, label: "Adresse", value: client.adresse },
      { icon: LocationIcon, label: "Département", value: client.code_postal },
      { icon: LocationIcon, label: "Ville", value: client.ville },
      { icon: PhoneIcon, label: "Téléphone", value: client.telephone },
      { icon: EmailIcon, label: "Email", value: client.email },
    ];

    const infoChantier = [
      {
        icon: LocationIcon,
        label: "Adresse chantier",
        value: client.adresse_chantier,
      },
      {
        icon: LocationIcon,
        label: "Département chantier",
        value: client.code_postal_chantier,
      },
      {
        icon: LocationIcon,
        label: "Ville chantier",
        value: client.ville_chantier,
      },
      {
        icon: LocationIcon,
        label: "Habitez-vous en île-de-france",
        value: client.is_idf ? "Oui" : "Non",
      },
      {
        icon: LogementIcon,
        label: "Type de logement",
        value: client.type_de_logement,
      },
      // { icon: ToolIcon, label: "Typologie de chantier", value: client.operation },
      // { icon: ToolIcon, label: "Type de travaux", value: client.operation },
      {
        icon: EnergyIcon,
        label: "Type de chauffage",
        value: client.type_de_chauffage,
      },
      {
        icon: EnergyIcon,
        label: "Énergie de chauffage actuel",
        value: client.energie_de_chauffage,
      },
      // { icon: RenewIcon, label: "Type de simulation", value: client.type_accompagnement },
      {
        icon: EuroIcon,
        label: "Revenu fiscal de référence",
        value: client.revenu_du_menage,
      },
      {
        icon: ProfileIcon,
        label: "Nombre de personnes dans le logement",
        value: client.nombre_de_personnes_dans_le_menage,
      },
      { icon: DocIcon, label: "Couleurs MPR", value: client.couleur_du_menage },
      {
        icon: LogementIcon,
        label: "Surface du logement",
        value: client.surface_logement ? `${client.surface_logement} m2` : "",
      },
      {
        icon: CakeIcon,
        label: "Âge du logement",
        value: client.age_du_logement,
      },
      { icon: ToolIcon, label: "Opération", value: client.operation },
      {
        icon: DocIcon,
        label: "Référence de la piste",
        value: client.reference,
      },
      {
        icon: Doc2Icon,
        label: "Type d’accompagnement",
        value: client.type_accompagnement,
      },
      {
        icon: EuroIcon,
        label: "Montant des travaux",
        value: client.montant_travaux ? `${client.montant_travaux} €` : "",
      },
      {
        icon: ProfileIcon,
        label: "Situation demandeur",
        value: client.situation_demandeur,
      },
      {
        icon: WindowIcon,
        label: "Type de fenêtres à installer",
        value: client.type_de_fenetres,
      },
      {
        icon: WindowIcon,
        label: "Nombre de fenêtres",
        value: client.nombre_fenetres,
      },
      {
        icon: CalendarIcon,
        label: "Délai des travaux",
        value: client.delai_travaux,
      },
      {
        icon: LogementIcon,
        label: "Équipement actuel",
        value: client.equipement_actuel,
      },
      {
        icon: EuroIcon,
        label: "Prime CEE",
        value: client.prime_cee ? `${client.prime_cee} €` : "",
      },
      {
        icon: EuroIcon,
        label: "Prime MPR",
        value: client.prime_mpr ? `${client.prime_mpr} €` : "",
      },
      {
        icon: EuroIcon,
        label: "Reste à payer",
        value: client.reste_a_payer ? `${client.reste_a_payer} €` : "",
      },
      {
        icon: ToolIcon,
        label: "Remplacement de l'ancien équipement",
        value: client.remplacement_ancien_equipement ? "Oui" : "Non",
      },
      { icon: EuroIcon, label: "Code promo", value: client.code_promo },
      {
        icon: EuroIcon,
        label: "Montant code promo",
        value: client.montant_code_promo
          ? `${client.montant_code_promo} €`
          : "",
      },
      {
        icon: EuroIcon,
        label: "Partenariat CA Touraine Poitou",
        value: client.partenariat_poitou ? "Oui" : "Non",
      },
      { icon: ToolIcon, label: "Usage", value: client.usage ?? "-" },
      {
        icon: ProfileIcon,
        label: "Espace bénéficiaire",
        value: client.client.data ? "Oui" : "Non",
      },
    ];

    return (
      <Main
        title={
          <>
            <button className="pr-3" onClick={handleOnClick}>
              <img src={btnBack} alt="retour" />
            </button>
            Vue détaillée de la simulation client : {client.prenom} {client.nom}
          </>
        }
      >
        {openDialog && (
          <Dialog onClose={() => setOpenDialog(false)} loading={dialogLoading}>
            {!dialogError && !dialogLoading && !dialogSuccess && (
              <div>
                <h2 className="my-5 text-xl font-semibold text-main-color text-center">
                  Êtes-vous sur de vouloir transformer cette piste en dossier ?
                </h2>
                <div className="flex my-2 justify-center">
                  <Button
                    label="Oui, je confirme"
                    className="mr-4"
                    onClick={transformerDossier}
                  ></Button>
                  <Button
                    label="Non, j’annule"
                    color="disabled"
                    onClick={() => setOpenDialog(false)}
                  ></Button>
                </div>
              </div>
            )}

            {dialogError && !dialogLoading && (
              <div className="min-h-[150px] flex items-center justify-center">
                <div className="text-red-500">{dialogError}</div>
              </div>
            )}

            {dialogSuccess && !dialogLoading && !dialogError && (
              <div className="min-h-[150px] flex items-center justify-center">
                <div className="my-5 text-xl font-semibold text-main-color text-center">
                  La piste à bien été transformée en dossier
                </div>
                {resultID && <Navigate to={`/dossiers/${resultID}`} />}
              </div>
            )}
          </Dialog>
        )}
        <div className="flex flex-row justify-between">
          {(client.statut === "Contacté" ||
            client.statut === "Nouveau" ||
            client.statut === "Demande de transformation") &&
            role !== "Consultation" && (
              <Button
                label="Transformer en dossier"
                className="mb-4"
                // icon={loading ? <LoadingIcon /> : <EuroIcon />}
                variant="contained"
                // disabled={selectedFiles.size === 0}
                onClick={() => {
                  setOpenDialog(true);
                  setDialogLoading(false);
                  setDialogSuccess(false);
                  setDialogError("");
                }}
              />
            )}
        </div>
        <div className="my-3">
          <StatusClient status={client.statut} id={fileID} role={role} />
        </div>

        <div className="flex-1 flex">
          <div className="w-1/2 pr-3">
            <Tab title="Informations bénéficiaire" borderStyle={borderStyle}>
              <div className="flex flex-wrap pl-2">
                {infoBeneficiare.map((block, index) => (
                  <div
                    key={index}
                    className={`w-[50%] ${index < 4 ? "mb-5" : ""}`}
                  >
                    <InfoBlock
                      logo={block.icon}
                      label={block.label}
                      value={block.value}
                    />
                  </div>
                ))}
              </div>
            </Tab>
          </div>
          <div className="w-1/2">
            <Tab title="Documents disponibles" borderStyle={borderStyle}>
              <div className="pl-2 flex">
                <DownloadIcon />
                <p className="ml-3 text-sm mt-1">
                  {client.documents.data.length} document(s) disponible{" "}
                </p>
              </div>

              <div className="pl-2">
                {client.documents.data.map((item) => (
                  <div
                    className="flex space-x-5 text-sm my-2 mr-3 mt-2"
                    key={item.id}
                  >
                    <div key={item.id} className="pt-1 max-w-[130px] truncate">
                      {item.attributes.nom}
                    </div>
                    <div className="flex text-main-color rounded-md border-0.5 border-blue font-semibold px-2">
                      <span className="pt-1">
                        <Euro />{" "}
                      </span>
                      <span className="pt-0.5">{item.attributes.type}</span>
                    </div>
                    <div className="text-light-color pt-1">
                      {transformTimestamp(item.attributes.updatedAt)}
                    </div>
                    <div className="text-light-color pt-1">
                      {formatFileSize(item.attributes.taille)}
                    </div>
                    <div>
                      <a
                        href={item.attributes.lien}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ViewIcon />
                      </a>
                    </div>
                    <div>
                      <a
                        href={item.attributes.lien_telechargement}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DlIcon />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
          </div>
        </div>

        <div>
          <Tab title="Informations chantier" borderStyle={borderStyle}>
            <div className="flex flex-wrap pl-2">
              {infoChantier.map((block, index) => (
                <div key={index} className={`w-[25%] mb-5`}>
                  <InfoBlock
                    logo={block.icon}
                    label={block.label}
                    value={block.value}
                  />
                </div>
              ))}
            </div>
          </Tab>
        </div>
      </Main>
    );
  }
}
