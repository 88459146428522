import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "components/AuthProvider";
import Dialog from "components/Dialog";
import Tab from "components/Tab";
import TabForm from "components/TabForm";
import useFetchApi from "hooks/useFetchApi";
import { formatDateFull, formatAmount, translateMessage } from "functions";
import iconDoc from "images/icons/doc.svg";
import Button from "components/Button";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";

const cancelReasons = [
  "Le montant de prime proposé ne me convient pas.",
  "Je préfère faire appel à un autre acteur que SORÉGIES.",
  "J'ai abandonné mon projet travaux.",
  "Autre :",
];
const otherReason = cancelReasons[cancelReasons.length - 1];

export default function Projet({ id, data, fetchData }) {
  const [fetchApi] = useFetchApi();
  const { isCustomer, isManager, isAdmin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [cancelProjectError, setCancelProjectError] = useState();
  const [cancelProjectSuccess, setCancelProjectSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState();
  const [textCancelReason, setTextCancelReason] = useState();

  const projet = data?.data?.attributes;
  const beneficiaire = projet?.beneficiaire?.data?.attributes;
  const chantiers = projet?.chantiers?.data;
  const fost = projet?.fosts?.data[0]?.attributes;

  let adresse = beneficiaire?.adresse ?? "";
  adresse += (adresse !== "" ? ", " : "") + (beneficiaire?.code_postal ?? "");
  adresse += (adresse !== "" ? " " : "") + (beneficiaire?.ville ?? "");

  const tabData = [
    {
      title: "Informations projet",
      icon: iconDoc,
      fields: [
        {
          name: "civilite",
          label: "Civilité",
          value: beneficiaire?.civilite,
          type: "readonly",
        },
        {
          name: "nom",
          label: "Nom",
          value: beneficiaire?.nom,
          type: "readonly",
        },
        {
          name: "prenom",
          label: "Prenom",
          value: beneficiaire?.prenom,
          type: "readonly",
        },
        {
          name: "adresseResidence",
          label: "Adresse de résidence",
          value: adresse,
          type: "readonly",
        },
        ...(chantiers?.map(({ attributes: chantier }, index) => ({
          name: `adresseChantier${index}`,
          label: "Adresse du chantier",
          value: `${chantier.adresse}, ${chantier.code_postal} ${chantier.ville}`,
          type: "readonly",
        })) || []),
        {
          name: "telephone",
          label: "Numéro de téléphone",
          value: beneficiaire?.telephone,
          type: "readonly",
        },
        {
          name: "email",
          label: "Adresse email",
          value: beneficiaire?.email,
          type: "readonly",
          visible: !isCustomer,
        },
        {
          name: "dossier",
          label: "Numéro de dossier client",
          value: projet?.reference,
          type: "readonly",
        },
        {
          name: "operation",
          label: "Libellé de l'opération",
          value: fost.numero_fost + " " + fost?.libelle_fost,
          type: "readonly",
        },
        {
          name: "dateCreation",
          label: "Date de création",
          value: formatDateFull(projet?.createdAt),
          type: "readonly",
        },
        {
          name: "Montant_des_travaux",
          label: "Estimation du coût des travaux",
          value: projet?.Montant_des_travaux,
          format: formatAmount,
          tooltip:
            "Ce montant est renseigné par vos soins sur la base de vos devis, il n'engage pas Sorégies.",
          type: "decimalnumber",
          maxLength: 10,
          decimals: 2,
          visible: isCustomer || isManager,
        },
        {
          name: "estimationPrimeCEE",
          label: "Estimation de la prime Sorégies",
          value: formatAmount(projet?.Prime_CEE),
          type: "readonly",
        },
        {
          name: "estimationPrimeMPR",
          label: "Estimation de la prime MPR",
          value: formatAmount(projet?.Prime_MPR),
          tooltip:
            "MaPrimeRénov' est une aide publique dont le montant varie en fonction des revenus et du type de travaux. MaPrimeRénov' n’est pas pris en charge par Sorégies.",
          type: "readonly",
          error: (
            <>
              MaPrimeRénov&apos; est une aide publique dont le montant varie en
              fonction des revenus et du type de travaux. MaPrimeRénov&apos;
              n&apos;est pas pris en charge par Sorégies.{" "}
              <a
                href="https://www.maprimerenov.gouv.fr/"
                target="_blank"
                rel="noreferrer"
              >
                <u>Voir le site de MaPrimeRénov&apos;</u>
              </a>
            </>
          ),
        },
        {
          name: "resteAPayer",
          label: "Reste à payer",
          // Le champ "Reste à Payer" doit être calculé comme suit : (Estimation des coûts de travaux - Estimation de la prime Sorégies - Estimation de la prime MPR).
          value: formatAmount(projet?.Reste_a_payer),
          type: "readonly",
          tooltip:
            "Le montant est automatiquement calculé sur la base des éléments que vous avez transmis, il n'engage pas Sorégies.",
          // Le champ "Reste à Payer" s'affiche uniquement si "Estimation des coûts de travaux" est renseigné
          visible: projet?.Montant_des_travaux && (isCustomer || isManager),
        },
        {
          name: "motifAbandon",
          label: "Motif de l'abandon",
          value: projet?.motif_abandon,
          type: "readonly",
          visible: projet?.statut === "Abandon" && (isManager || isAdmin),
        },
      ],
    },
  ];

  const closeCancelDialog = () => {
    setOpenDialog(false);
  };

  const openCancelDialog = () => {
    setCancelProjectSuccess(false);
    setCancelProjectError(false);
    setOpenDialog(true);
  };

  const doCancelProject = async () => {
    try {
      setCancelProjectSuccess(false);
      setCancelProjectError(false);
      setLoading(true);
      const reason =
        selectedCancelReason === otherReason
          ? textCancelReason
          : selectedCancelReason;
      await fetchApi(`dossier/cancel/${id}`, { body: { reason } });
      fetchData();
      setCancelProjectSuccess(true);
    } catch (error) {
      setLoading(false);
      setCancelProjectError(translateMessage(error.message));
    } finally {
      setLoading(false);
    }
  };

  if (!data) {
    return (
      <div className="mt-4 w-[727px]">
        <LoadingIcon />
      </div>
    );
  } else {
    return (
      <>
        <div className="mt-4 w-[727px]">
          <div>
            {tabData.map((tab, tabIndex) => (
              <Tab title={tab.title} icon={tab.icon} key={`tab_${tabIndex}`}>
                <TabForm tab={tab} id={id} fetchData={fetchData}></TabForm>
              </Tab>
            ))}
          </div>
          {projet?.statut !== "Abandon" && isCustomer && (
            <Button
              label="Abandonner le projet"
              className="mt-3"
              variant="contained"
              color="blue"
              type="submit"
              icon={loading ? <LoadingIcon /> : ""}
              onClick={openCancelDialog}
            />
          )}
        </div>
        {openDialog && (
          <Dialog onClose={closeCancelDialog}>
            {!cancelProjectSuccess && !cancelProjectError && (
              <div>
                <p className="my-5 text-xl font-semibold text-main-color text-center">
                  Confirmez-vous l&apos;abandon de votre projet&nbsp;?
                  <br />
                  Indiquez le motif d&apos;abandon
                </p>
                <form className="my-5 text-md text-main-color">
                  <ul className="">
                    {cancelReasons.map((reason, index) => (
                      <li key={reason} className="mb-2">
                        <input
                          type="radio"
                          name="reason"
                          id={`reason${index}`}
                          value={reason}
                          className="cursor-pointer"
                          onChange={() => setSelectedCancelReason(reason)}
                          checked={selectedCancelReason === reason}
                        />
                        <label
                          className="cursor-pointer pl-2"
                          htmlFor={`reason${index}`}
                        >
                          {reason}
                        </label>
                      </li>
                    ))}
                  </ul>
                  <textarea
                    className="w-full rounded-l border-0.5 border-stone-400 p-2"
                    rows="5"
                    defaultValue={textCancelReason}
                    onChange={(event) =>
                      setTextCancelReason(event.target.value.trim())
                    }
                    onClick={() => setSelectedCancelReason(otherReason)}
                  ></textarea>
                </form>
                <div className="flex my-2 justify-center">
                  <Button
                    label="Oui, je confirme"
                    className="mr-4"
                    onClick={doCancelProject}
                    icon={loading ? <LoadingIcon /> : ""}
                    disabled={
                      loading ||
                      !selectedCancelReason ||
                      (selectedCancelReason === otherReason &&
                        !textCancelReason)
                    }
                  ></Button>
                  <Button
                    label="Non, j’annule"
                    color="disabled"
                    onClick={closeCancelDialog}
                  ></Button>
                </div>
              </div>
            )}
            {(cancelProjectSuccess || cancelProjectError) && (
              <div>
                {cancelProjectSuccess && !cancelProjectError && (
                  <p className="my-5 text-xl font-semibold text-main-color text-center">
                    Votre projet a bien été annulé.
                  </p>
                )}
                {cancelProjectError && (
                  <p className="my-5 text-xl font-semibold text-red-500 text-center">
                    {cancelProjectError}
                  </p>
                )}
                <div className="flex my-2 justify-center">
                  <Button
                    label="Terminé"
                    className="mr-4"
                    onClick={closeCancelDialog}
                  ></Button>
                </div>
              </div>
            )}
          </Dialog>
        )}
      </>
    );
  }
}

Projet.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  fetchData: PropTypes.func.isRequired,
};
